import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from '../components/Seo';

// markup
const ErrorPage = ({ data }) => {
	const page = data && data.page;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<h1>{page.title}</h1>
			<Link to="/">Back home</Link>
		</>
	);
};

export default ErrorPage;

export const query = graphql`
	query ErrorQuery {
		page: sanityErrorPage {
			id
			title
			seo {
				...seoFields
			}
		}
	}
`;
